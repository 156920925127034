<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <label for="name">Name</label>
      <input
        type="text"
        class="form-control form-control-sm"
        required
        v-model="name"
      />
    </div>
    <div class="form-group">
      <label for="zipcodes">Postleitzahlen</label>
      <select class="form-control" multiple v-model="zipcodes">
        <option v-for="n in 99" :value="n < 10 ? `0${n}` : '' + n" :key="n">
          {{ n < 10 ? `0${n}` : n }}
        </option>
      </select>
    </div>
    <div class="form-group mb-0">
      <button
        type="submit"
        class="btn btn-block btn-success btn-sm"
        :disabled="requesting"
      >
        Speichern
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'create-team-form',
  props: {
    requesting: {
      type: Boolean,
      required: true,
    },
    role: {
      type: String,
      default: 'guest',
    },
  },
  data() {
    return {
      name: '',
      zipcodes: [],
    };
  },
  methods: {
    onSubmit() {
      this.$emit('createTeam', {
        name: this.name,
        zipcodes: this.zipcodes,
      });
      this.name = '';
      this.zipcodes = [];
    },
  },
};
</script>
