<template>
  <table class="table table-sm table-bordered table-sm">
    <thead>
      <tr>
        <th>Name</th>
        <th>Postleitzahlen</th>
        <th class="text-center" width="1%">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="team in teams" :key="team._id">
        <td>{{ team.name }}</td>
        <td>{{ team.zipcodes }}</td>
        <td>
          <button
            type="button"
            class="btn btn-sm btn-danger btn-block"
            :disabled="requesting || role === 'guest'"
            @click="onDelete(team._id)"
          >
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'teams-table',
  props: {
    teams: {
      type: Array,
      required: true,
    },
    requesting: {
      type: Boolean,
      required: true,
    },
    role: {
      type: String,
      default: 'guest',
    },
  },
  methods: {
    onDelete(id) {
      this.$emit('deleteTeam', id);
    },
  },
};
</script>
