<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-8">
        <h3>
          Teams
          <small class="text-muted">
            <span v-if="!requesting">{{ teamsCount }}</span>
            <i class="fa fa-fw fa-spinner fa-pulse" v-if="requesting"></i>
          </small>
        </h3>
      </div>
      <div class="col col-xl-2 text-right">
        <input
          v-model="page"
          type="number"
          min="1"
          :max="pageCount"
          class="d-inline"
        />
        /{{ pageCount }}
      </div>
    </div>
    <div class="form-row">
      <div class="col-12 col-xl-3">
        <div class="form-group">
          <input
            type="text"
            v-model="query.name"
            class="form-control form-control-sm"
            placeholder="Name suchen..."
          />
        </div>
      </div>
      <div class="col-12 col-xl-2">
        <div class="form-group">
          <input
            type="text"
            v-model="query.zipcode"
            class="form-control form-control-sm"
            placeholder="Postleitzahl suchen..."
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-9 col-xl-10">
        <teams-table
          :teams="teams"
          :requesting="requesting"
          @deleteTeam="onDeleteTeam"
          :role="role"
          v-if="teams.length"
        ></teams-table>
      </div>
      <div
        class="col-12 col-lg-3 col-xl-2"
        v-if="role === 'admin' || role === 'user'"
      >
        <div class="card">
          <div class="card-body">
            <create-team-form
              @createTeam="onCreateTeam"
              :requesting="requesting"
              :role="role"
            ></create-team-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamsTable from '@/components/teams/TeamsTable';
import CreateTeamForm from '@/components/teams/CreateTeamForm';
import { createTeam, getTeams, getTeamsCount, deleteTeam } from '@/api';
import { auth } from '@/firebase';
import { debounce } from 'lodash';

export default {
  name: 'teams',
  components: {
    TeamsTable,
    CreateTeamForm,
  },
  data() {
    return {
      page: 1,
      teams: [],
      teamsCount: 0,
      requesting: false,
      role: '',
      query: {
        limit: 50,
        offset: 0,
        name: '',
        zipcode: '',
      },
    };
  },
  watch: {
    page: {
      handler(page) {
        if (page > 0) {
          this.query.offset = page - 1;
        }
      },
    },
    query: {
      handler: debounce(async function (query) {
        this.requesting = true;
        this.teams = await getTeams(query);
        this.requesting = false;
      }, 500),
      deep: true,
    },
  },
  async created() {
    this.requesting = true;

    try {
      const idTokenResult = await auth.currentUser.getIdTokenResult();

      this.role = idTokenResult.claims.role;

      this.teams = await getTeams(this.query);
      this.teamsCount = await getTeamsCount();
      this.requesting = false;
    } catch (err) {
      console.error(err);
      this.requesting = false;
    }
  },
  methods: {
    async onDeleteTeam(id) {
      if (confirm('Sind Sie sicher, dass Sie dieses Team löschen möchten?')) {
        try {
          this.requesting = true;

          await deleteTeam(id);

          this.teams = this.teams.filter((team) => team._id !== id);
          this.teamsCount--;

          this.requesting = false;
        } catch (err) {
          console.error(err);

          this.requesting = false;
        }
      }
    },
    async onCreateTeam(team) {
      try {
        const res = await createTeam(team);

        this.teams.push(res);
        this.teamsCount++;
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.teamsCount / this.query.limit);
    },
  },
};
</script>
